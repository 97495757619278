import type { StyleRules, Theme } from '@material-ui/core/styles';

const birthdayFieldStyles = (theme: Theme) => ({
  formGroup: {
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: '0.75em',
  },
} as const satisfies StyleRules);

export default birthdayFieldStyles;
