import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';

import { sortByKey } from '../../utils/arrays';
import { notBlankValidator } from '../../utils/forms';
import { withIntl } from '../../utils/withIntl';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import followerFavoriteLocationQuery from '../../libs/gql/queries/followers/followerFavoriteLocationQuery.gql';
import updateFollowerFavoriteLocationMutation from '../../libs/gql/mutations/followers/updateFollowerFavoriteLocationMutation.gql';

import BasicForm, { SubmitGroup } from '../../admin/shared/forms/BasicForm';
import Loading from '../Loading';
import Query from '../Query';
import SelectGroup from '../../admin/shared/forms/SelectGroup';

const UserFavoriteLocationForm = props => (
  <Query
    fetchPolicy="network-only"
    query={followerFavoriteLocationQuery}
    variables={{
      restaurantId: props.restaurant.id,
      userId: props.currentUser.id,
    }}
  >
    {({ data, loading }) => {
      if (loading || !data || !data.followerFavoriteLocation) {
        return <Loading />;
      }
      const follower = data.followerFavoriteLocation;
      return (
        <BasicForm
          defaultValues={{
            favoriteLocationId: follower.favoriteLocation && follower.favoriteLocation.id,
            followerId: follower.id,
          }}
          mutate={{
            mutation: updateFollowerFavoriteLocationMutation,
            toVariables: variables => ({
              ...variables,
              favoriteLocationId: variables.favoriteLocationId,
            }),
          }}
        >
          <SelectGroup
            field="favoriteLocationId"
            options={sortByKey(props.locations.map(option => ({
              label: option.name,
              value: option.id,
            })), 'label')}
            title={props.t('models.follower.favorite_location.name')}
            validate={notBlankValidator}
            variant="standard"
          />
          <SubmitGroup color="primary" justify="center" title={props.t('models.follower.favorite_location.submit')} />
        </BasicForm>
      );
    }}
  </Query>
);

UserFavoriteLocationForm.propTypes = {
  currentUser: currentUserShape.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
)(UserFavoriteLocationForm);
