import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Typography } from '@popmenu/common-ui';

import { withStyles } from '../../utils/withStyles';
import profileStyles from '../../assets/jss/shared/profileStyles';
import { withIntl } from '../../utils/withIntl';
import { withSnackbar } from '../../utils/withSnackbar';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';

import AuthySummary from './AuthySummary';
import Divider from '../../admin/shared/forms/Divider';
import SignOutButton from '../../admin/shared/SignOutButton';
import UserAvatarForm from '../profile/UserAvatarForm';
import UserFavoriteLocationForm from '../profile/UserFavoriteLocationForm';
import UserPasswordForm from '../profile/UserPasswordForm';
import UserProfileForm from '../profile/UserProfileForm';
import UserSubscriptionsForm from '../profile/UserSubscriptionsForm';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';

const ProfileForm = ({ afterSignOut, classes, currentUser, isAdmin, locations, restaurant }) => {
  if (!currentUser) {
    return null;
  }
  return (
    <React.Fragment>
      <AH typography variant="h3" align="center" gutterBottom><FormattedMessage id="users.profile" defaultMessage="Profile" /></AH>
      <AHLevelProvider>
        <UserAvatarForm />

        <UserProfileForm />
        <Divider className={classes.profileDivider} />

        <UserPasswordForm />
        <Divider className={classes.profileDivider} />

        {isAdmin && currentUser.authyStatus !== 'authy_enabled' && (
          <React.Fragment>
            <AuthySummary />
            <Divider className={classes.profileDivider} />
          </React.Fragment>
        )}

        {!isAdmin && restaurant && restaurant.locationsCount > 1 && (
          <React.Fragment>
            <UserFavoriteLocationForm restaurant={restaurant} locations={locations} />
            <Divider className={classes.profileDivider} />
          </React.Fragment>
        )}

        <UserSubscriptionsForm />
        <Divider className={classes.profileDivider} />

        <Typography align="center">
          <SignOutButton
            isAdmin={isAdmin}
            onCompleted={afterSignOut}
          />
        </Typography>
      </AHLevelProvider>
    </React.Fragment>
  );
};

ProfileForm.defaultProps = {
  afterSignOut: null,
  currentUser: null,
  isAdmin: false,
  restaurant: null,
};

ProfileForm.propTypes = {
  afterSignOut: PropTypes.func,
  classes: PropTypes.object.isRequired,
  currentUser: currentUserShape,
  isAdmin: PropTypes.bool,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  showSnackbar: PropTypes.func.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
  withStyles(profileStyles),
  withSnackbar,
)(ProfileForm);
