import type { StyleRules, Theme } from '@material-ui/core/styles';

const imageLibraryStyles = (theme: Theme) => ({
  dropZone: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    backgroundColor: theme.palette.surface.light,
    border: `1px dashed ${theme.palette.text.secondary}`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  dropZoneDisabled: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.text.disabled,
    cursor: 'default',
  },
  galleryImageCloseIcon: {
    background: theme.palette.surface.light,
    borderRadius: '50%',
  },
  galleryImageContainer: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  galleryImageContainerHide: {
    display: 'none',
  },
  galleryImageUploadButtonImg: {
    height: '100%',
    maxHeight: theme.spacing(20),
    objectFit: 'contain',
    padding: theme.spacing(2),
    top: 0,
    transform: 'none',
    width: 'auto',
  },
  gallerySortableImageUploadButtonImg: {
    borderRadius: theme.spacing(3.5),
    cursor: 'grab',
    height: theme.spacing(20),
    objectFit: 'cover',
    padding: theme.spacing(2),
    top: 0,
    transform: 'none',
    width: theme.spacing(20),
  },
  galleryWrapper: {
    maxHeight: '30vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  uploadIcon: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1.5),
  },
} as const satisfies StyleRules);

export default imageLibraryStyles;
