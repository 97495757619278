import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Box, Button, Icon } from '@popmenu/common-ui';
import { Eye } from '@popmenu/web-icons';

import { notBlankValidator, passwordValidator } from '../../utils/forms';
import { withIntl } from '../../utils/withIntl';
import { withSnackbar } from '../../utils/withSnackbar';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import updateUserMutation from '../../libs/gql/mutations/users/updateUserMutation.gql';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';

const UserPasswordForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <BasicForm
      labelPosition="top"
      mutate={{
        mutation: updateUserMutation,
        onCompleted: () => {
          props.showSnackbar('Your password has been updated!');
        },
        toVariables: variables => ({
          userId: props.currentUser.id,
          userInput: {
            ...variables,
          },
        }),
      }}
    >
      <TextFieldGroup
        field="currentPassword"
        title={props.t('models.user.current_password')}
        type="password"
        validate={notBlankValidator}
        variant="standard"
      />
      <TextFieldGroup
        field="password"
        title={props.t('models.user.password')}
        type={showPassword ? 'text' : 'password'}
        validate={passwordValidator}
        helperText={props.t('models.user.password_helper_text')}
        variant="standard"
        InputProps={{
          endAdornment:
          (
            <React.Fragment>
              <Button
                data-cy={'preview_password'}
                data-tour-id="settings-my-profile-preview-password-button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                size="xs"
                variant="text"
              >
                <Box color={!showPassword && 'gray.dark'} display="flex">
                  <Icon icon={Eye} />
                </Box>
              </Button>
            </React.Fragment>
          ),
        }}
      />
      <TextFieldGroup
        field="passwordConfirmation"
        title={props.t('models.user.password_confirmation')}
        type="password"
        validate={passwordValidator}
        variant="standard"
      />
      <SubmitGroup
        color="primary"
        justify="center"
        title={props.t('users.update_password')}
        data-tour-id="settings-my-profile-update-password-button"
      />
    </BasicForm>
  );
};

UserPasswordForm.propTypes = {
  currentUser: currentUserShape.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
  withSnackbar,
)(UserPasswordForm);
