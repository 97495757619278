import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { compose } from '@shakacode/recompose';
import { withIntl } from '../../utils/withIntl';

import BasicModal from '../../admin/shared/BasicModal';
import { closeProfileModal } from '../ModalActions';
import { withRestaurant } from '../../utils/withRestaurant';
import ProfileForm from '../sessions/ProfileForm';
import Loading from '../Loading';
import Query from '../Query';

import locationsQuery from '../../libs/gql/queries/locations/restaurantWithLocationsQuery.gql';

const ProfileModal = ({ restaurant, t }) => {
  const showProfileModal = useSelector(state => state.modals.showProfileModal);
  const dispatch = useDispatch();

  if (!restaurant || !showProfileModal) {
    return null;
  }
  return (
    <BasicModal
      closeModal={() => dispatch(closeProfileModal())}
      show={showProfileModal}
      size="sm"
      title={t('nav.profile')}
    >
      <Query
        query={locationsQuery}
        variables={{
          restaurantId: restaurant.id,
        }}
      >
        {({ data, loading }) => {
          if (loading || !data || !data.restaurant.locations) {
            return <Loading size="lg" />;
          }

          return (
            <ProfileForm
              afterSignOut={() => dispatch(closeProfileModal())}
              restaurant={restaurant}
              locations={data.restaurant.locations}
            />
          );
        }}
      </Query>
    </BasicModal>
  );
};

ProfileModal.propTypes = {
  restaurant: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withRestaurant,
  withIntl,
)(ProfileModal);
