import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import request from 'superagent';
import Dropzone from 'react-dropzone';
import { Icon, Typography, Box } from '@popmenu/common-ui';
import { Upload } from '@popmenu/web-icons';

import { getApolloClient } from '~/lazy_apollo';
import { withIntl } from '../../../../utils/withIntl';
import { classNames, withStyles } from '../../../../utils/withStyles';
import imageLibraryStyles from '../../../../assets/jss/admin/imageLibraryStyles';
import awsSignatureQuery from '../../../../libs/gql/queries/signatures/awsSignatureQuery.gql';
import createUploadedMutation from '../../../../libs/gql/mutations/uploads/createUploadMutation.gql';
import updateUploadMutation from '../../../../libs/gql/mutations/uploads/updateUploadMutation.gql';
import uploadableWasUpdatedSubscription from '../../../../libs/gql/subscriptions/uploadables/uploadableWasUpdatedSubscription.gql';
import restaurantUploadsQuery from '../../../../libs/gql/queries/restaurants/restaurantUploadsQuery.gql';
import Grid from '../../../../shared/Grid';
import { toQueryName } from '../../../../utils/apollo';
import { withSnackbar } from '../../../../utils/withSnackbar';

class UploadDropzone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.uploadId = 1;
    this.uploads = [];

    this.getUploadType = this.getUploadType.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploaded = this.onUploaded.bind(this);
  }

  getUploadType(type) {
    if (['brand', 'content', 'menu'].includes(this.props.accept)) {
      if (['ai', 'postscript', 'psd', 'photoshop', 'pdf', 'doc', 'txt', 'word'].some(i => type.includes(i))) {
        return 'file';
      } else {
        return 'image';
      }
    }
    return this.props.accept;
  }

  onUploadProgress(uploadId, fileName, progress, rawFile, s3Key) {
    const foundUpload = this.uploads.find(upload => upload.id === uploadId);
    if (foundUpload) {
      foundUpload.progress = progress;
    } else {
      this.uploads.push(
        {
          fileName,
          id: uploadId,
          progress,
          rawFile,
          s3Key,
        },
      );
    }

    this.props.onUploaded(this.uploads);
  }

  refreshUploadStatus(upload, uploadId) {
    getApolloClient().then(client => client.subscribe({
      query: uploadableWasUpdatedSubscription,
      variables: {
        uploadableId: upload.id,
        uploadableType: this.props.accept,
      },
    }).subscribe({
      error: (error) => {
        console.warn(`GenericSubscription error: ${error.toString()}`);
      },
      next: ({ data }) => {
        if (data?.uploadableWasUpdated?.uploaded) {
          const foundUpload = this.uploads.find(u => u.id === uploadId);
          foundUpload.popModel = data.uploadableWasUpdated;
          this.queryForLibraryGallery(foundUpload.popModel, client);
        }
      },
    }));
  }

  queryForLibraryGallery(record, client) {
    const uploadType = this.getUploadType(record.format);

    const variables = {
      allUploads: true,
      pagination: {
        limit: 20,
        offset: 0,
        sortBy: 'created_at',
        sortDir: 'desc',
      },
      restaurantId: this.props.restaurant && this.props.restaurant.id,
      uploadType,
    };

    // client.readQuery throws invariant violation when there's nothing in the cache for the query
    try {
      const data = client.readQuery({
        query: restaurantUploadsQuery,
        variables,
      });

      client.writeQuery({
        data: {
          restaurant: {
            ...data.restaurant,
            uploads: {
              ...data.restaurant.uploads,
              records: [record, ...data.restaurant.uploads.records],
            },
          },
        },
        query: restaurantUploadsQuery,
        variables,
      });
    } catch (err) {
      console.warn(`[POPMENU] Error with upload cache: ${err.toString()}`);
    }

    this.props.onUploaded(this.uploads);
  }

  onUploaded({ uploadId, fileName, fileSize, fileFormat, etag, key, contentType, height, width }) {
    const input = {
      bytes: fileSize,
      contentType,
      etag,
      format: fileFormat,
      height,
      originalFilename: fileName,
      restaurantId: this.props.restaurant && this.props.restaurant.id,
      s3Key: key,
      width,
    };
    const uploadType = this.getUploadType(input.format);

    if (this.props.restaurant.onboardingInfo && this.props.category) {
      input.category = this.props.category;
    }

    if (this.props.uploadId) {
      getApolloClient().then(client => client.mutate({
        mutation: updateUploadMutation,
        onError: () => this.props.showSnackbarError(this.props.t('errors.upload.failed')),
        variables: {
          id: this.props.uploadId,
          uploadInput: input,
          uploadType,
        },
      }));
    } else {
      getApolloClient().then(client => client.mutate({
        mutation: createUploadedMutation,
        onError: () => this.props.showSnackbarError(this.props.t('errors.upload.failed')),
        refetchQueries: this.props.refetchQuery && [toQueryName(this.props.refetchQuery)],
        variables: {
          input,
          isImport: this.props.isImport,
          isOnboarding: this.props.isOnboarding,
          uploadType,
        },
      }).then((results) => {
        if (results && results.data && results.data.createUpload) {
          const foundUpload = this.uploads.find(upload => upload.id === uploadId);

          if (results.data.createUpload.uploaded) {
            foundUpload.popModel = results.data.createUpload;
            this.queryForLibraryGallery(foundUpload.popModel, client);
          } else {
            this.refreshUploadStatus(results.data.createUpload, uploadId);
          }
        }
      }).catch((error) => {
        // Clear upload in progress indicator
        this.uploads = this.uploads.filter(upload => (upload.id !== uploadId));
        this.props.onUploaded(this.uploads);

        this.props.showSnackbarError(error.message);
      }));
    }
  }

  async onUploadDroppedBatched(acceptedFiles, rejectedFiles) {
    const { limitUploads, uploadsRemaining } = this.props;
    let importCount = 0;

    const uploadBatch = async (files) => {
      const batchFiles = files.splice(0, 50);
      const uploadPromises = batchFiles.map(async (file) => {
        if (!limitUploads || uploadsRemaining - importCount > 0) {
          const newId = this.uploadId;
          this.uploadId += 1;
          const fileName = file.name;

          if (file.type.startsWith('image/')) {
            return this.verifyImageAndUploadToS3Batched(file, newId, fileName);
          } else {
            return this.uploadToS3Batched(file, newId, fileName);
          }
        }

        return null;
      });

      const results = await Promise.all(uploadPromises);
      importCount += results.filter(result => result !== null).length;

      if (files.length > 0) {
        // There is a rate limit of 150 requests per 10 seconds from Cloudflare
        // We throttle after each batch in order to avoid hitting a rate limit error
        setTimeout(() => uploadBatch(files), 10000);
      }
    };

    await uploadBatch([...acceptedFiles]);

    if (rejectedFiles.length > 0) {
      this.props.showSnackbarError(this.props.t(`errors.upload.invalid.${this.props.accept}`));
    }
  }

  async verifyImageAndUploadToS3Batched(file, fileId, fileName) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        try {
          const img = new Image();
          img.src = reader.result;
          img.onload = async () => {
            const { width, height } = img;
            const response = await this.uploadToS3Batched(file, fileId, fileName, height, width);
            resolve(response);
          };
        } catch (err) {
          reject(err);
        }
      };
    });
  }

  async uploadToS3Batched(file, fileId, fileName, height = null, width = null, uploadedAttempt = 1) {
    try {
      const results = await getApolloClient().then(client => client.query({
        fetchPolicy: 'network-only',
        query: awsSignatureQuery,
        variables: {
          input: {
            fileName,
            restaurantId: this.props.restaurant && this.props.restaurant.id,
            uploadId: this.props.uploadId,
            uploadType: this.getUploadType(file.type),
          },
        },
      }));
      if (results && results.data && results.data.awsSignature) {
        this.onUploadProgress(fileId, fileName, 0, file, results.data.awsSignature.id);

        const uploadResponse = await new Promise((resolve, reject) => {
          request
            .put(results.data.awsSignature.url, file)
            .set('Cache-Control', `max-age=${60 * 60 * 24 * 365}`)
            .end(async (error, response) => {
              if (response && response.ok) {
                resolve(response);
              } else {
                reject(error);
              }
            });
        });

        this.onUploaded({
          contentType: file.type,
          etag: uploadResponse.headers.etag,
          fileFormat: results.data.awsSignature.format,
          fileName,
          fileSize: file.size,
          key: results.data.awsSignature.id,
          uploadId: fileId,
          ...(height ? { height } : {}),
          ...(width ? { width } : {}),
        });

        return 'Upload completed successfully';
      }
      return 'Upload failed';
    } catch (error) {
      if (uploadedAttempt < 5) {
        return this.uploadToS3Batched(file, fileId, fileName, height, width, uploadedAttempt + 1);
      } else {
        this.props.showSnackbarError(this.props.t('errors.upload.failed'));
        return 'Upload failed';
      }
    }
  }

  acceptableField() {
    const files = [
      '.xml',
      'application/csv',
      'application/json',
      'application/msword',
      'application/pdf',
      'application/psd',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/x-photoshop',
      'application/xml',
      'image/psd',
      'image/vnd.adobe.photoshop',
      'text/comma-separated-values',
      'text/csv',
      'text/html',
      'text/plain',
      'text/tab-separated-values',
      'text/x-comma-separated-values',
      'text/x-csv',
    ];

    const fonts = [
      'font/*',
      '.otf',
      '.ttf',
      '.woff',
      '.woff2',
      'application/font-sfnt',
      'application/font-woff',
      'application/font-woff2',
      'application/x-font-otf',
      'application/x-font-ttf',
    ];

    const images = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
    ];

    const multipleFiles = images.concat([
      'application/pdf',
      'application/postscript',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'text/plain',
      'application/psd',
      'image/psd',
      'image/vnd.adobe.photoshop',
      'application/x-photoshop',
    ]);

    switch (this.props.accept) {
      case 'file':
      case 'import':
        return files.join(',');
      case 'font':
        return fonts.join(',');
      case 'avatar':
      case 'image':
        return images.join(',');
      case 'brand':
      case 'content':
      case 'menu':
        return multipleFiles.join(',');
      case 'video':
        return 'video/*';
    }

    return '';
  }

  render() {
    return (
      <Dropzone
        multiple={this.props.multiple}
        accept={this.acceptableField()}
        onDrop={(acceptedFiles, rejectedFiles) => {
          this.onUploadDroppedBatched(acceptedFiles, rejectedFiles);
        }}
        disableClick={false}
        disabled={this.props.isLimitReached}
      >
        {({ getRootProps, getInputProps }) => (
          <React.Fragment>
            {this.props.children && (
              <div
                {...getRootProps({
                  'data-tour-id': this.props.dataTourId,
                })}
              >
                <input
                  {...getInputProps()}
                />
                {this.props.children}
              </div>
            )}
            {!this.props.children && (
              <div
                {...getRootProps({
                  className: classNames(this.props.classes.dropZone, this.props.isLimitReached && this.props.classes.dropZoneDisabled),
                  'data-tour-id': this.props.dataTourId,
                })}
              >
                <input
                  {...getInputProps()}
                />
                <Grid container justify="center" alignItems="center" spacing={0}>
                  <Box paddingX={2} paddingY={3}>
                    <Icon className={this.props.classes.uploadIcon} icon={Upload} />
                    <Typography
                      align="center"
                      variant="body1"
                    >
                      {this.props.t(`media_library.drop_${this.props.accept}s_message`)}
                      <FormattedMessage id="media_library.click_message" defaultMessage=", or click to select a file" />
                    </Typography>
                  </Box>
                </Grid>
              </div>
            )}
          </React.Fragment>
        )}
      </Dropzone>
    );
  }
}

UploadDropzone.defaultProps = {
  category: null,
  children: null,
  dataTourId: 'upload-dropzone',
  isImport: false,
  isLimitReached: false,
  isOnboarding: false,
  limitUploads: false,
  multiple: true,
  refetchQuery: null,
  restaurant: null,
  uploadId: null,
  uploadsRemaining: 0,
};

UploadDropzone.propTypes = {
  accept: PropTypes.oneOf(['avatar', 'brand', 'content', 'file', 'font', 'image', 'import', 'menu', 'video']).isRequired,
  category: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  dataTourId: PropTypes.string,
  isImport: PropTypes.bool,
  isLimitReached: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  limitUploads: PropTypes.bool,
  multiple: PropTypes.bool,
  onUploaded: PropTypes.func.isRequired,
  refetchQuery: PropTypes.object,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
  }),
  showSnackbarError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  uploadId: PropTypes.number,
  uploadsRemaining: PropTypes.number,
};

export default compose(
  withIntl,
  withSnackbar,
  withStyles(imageLibraryStyles),
)(UploadDropzone);
