import { makeStyles } from '@popmenu/common-ui';

export const useStyles = makeStyles(({ spacing }) => ({
  checkboxTitle: {
    fontSize: '0.85rem',
    marginTop: spacing(2),
  },
  gridContainer: {
    marginBottom: spacing(2),
    marginTop: spacing(-1),
  },
  gridPhone: {
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
  gridToggle: {
    flexWrap: 'wrap',
    marginTop: spacing(1),
    maxWidth: '100%',
  },
  titleTooltip: {
    alignItems: 'center',
    display: 'inline-flex',
    marginTop: spacing(2),
  },
}));
