import type { StyleRules, Theme } from '@material-ui/core/styles';

const userAvatarFormStyles = (theme: Theme) => ({
  avatar: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    height: theme.spacing(16),
    margin: '0 auto',
    overflow: 'visible',
    position: 'relative',
    width: theme.spacing(16),
  },
  avatarButton: {
    background: 'none',
    border: 'none',
    bottom: '0',
    position: 'absolute',
    right: `-${theme.spacing(3)}px`,
  },
  avatarLabel: {
    '& input[type="file"]': {
      opacity: 0,
    },
  },
  avatarWrap: {
    margin: `${theme.spacing(2)}px auto`,
    overflow: 'hidden',
  },
} as const satisfies StyleRules);

export default userAvatarFormStyles;
