import type { StyleRules, Theme } from '@material-ui/core/styles';

const profileStyles = (theme: Theme) => ({
  profileDivider: {
    margin: `${theme.spacing(4)}px 0`,
  },
  profileLinks: {
    textAlign: 'center',
  },
} as const satisfies StyleRules);

export default profileStyles;
