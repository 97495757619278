import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';

import { withIntl } from '../../../utils/withIntl';
import { withStyles } from '../../../utils/withStyles';
import birthdayFieldStyles from '../../../assets/jss/shared/birthdayFieldStyles';

import FormGroup from '../../../admin/shared/forms/FormGroup';
import Grid from '../../../shared/Grid';
import SelectGroup from '../../../admin/shared/forms/SelectGroup';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BirthdayFields = props => (
  <FormGroup className={props.classes.formGroup}>
    <Typography className={props.classes.title}><FormattedMessage id="models.user.birthday" defaultMessage="Birthday" /></Typography>
    <Grid container>
      <Grid item xs={6}>
        <SelectGroup
          aria-label={props.t('models.user.birth_month')}
          field="birthMonth"
          isSearchable={false}
          options={MONTHS.map((option, i) => ({
            label: props.t(`models.user.birth_months.${option}`),
            value: i + 1,
          }))}
          placeholder={props.t('models.user.birth_month')}
          variant={props.variant}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectGroup
          aria-label={props.t('models.user.birth_month')}
          field="birthDay"
          isSearchable={false}
          options={[...Array(32).keys()].splice(1).map((option, i) => ({
            label: props.t(`models.user.birth_days.${option}`),
            value: i + 1,
          }))}
          placeholder={props.t('models.user.birth_day')}
          variant={props.variant}
        />
      </Grid>
    </Grid>
  </FormGroup>
);

BirthdayFields.defaultProps = {
  birthDay: null,
  birthMonth: null,
  variant: 'standard',
};

BirthdayFields.propTypes = {
  birthDay: PropTypes.number,
  birthMonth: PropTypes.number,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default withStyles(birthdayFieldStyles)(withIntl(BirthdayFields));
