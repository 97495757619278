import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';
import { Button } from '@popmenu/admin-ui';

import { withIntl } from '../../utils/withIntl';
import { withCurrentUser } from '../../shared/CurrentSessionProvider';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';
import { popmenuConfigShape } from '../../utils/popmenuConfigShape';
import { withSnackbar } from '../../utils/withSnackbar';
import destroyAdminSessionMutation from '../../libs/gql/mutations/users/destroyAdminSessionMutation.gql';
import destroySessionMutation from '../../libs/gql/mutations/users/destroySessionMutation.gql';
import adminSessionQuery from '../../libs/gql/queries/users/adminSessionQuery.gql';
import currentSessionQuery from '../../libs/gql/queries/users/currentSessionQuery.gql';

const SignOutButton = ({ currentUser, history, isAdmin, onCompleted, popmenuConfig, showSnackbarError }) => {
  const [destroySession, { loading }] = useMutation(isAdmin ? destroyAdminSessionMutation : destroySessionMutation);
  if (!currentUser) {
    return null;
  }
  return (
    <Button
      loading={loading}
      variant="text"
      onClick={() => {
        if (loading) {
          return;
        }
        destroySession().then(({ data }) => {
          // Custom callback
          if (typeof onCompleted === 'function') {
            onCompleted();
          }

          // Update top-level session query with new session returned by destroy mutation
          getApolloClient().then(client => client.writeQuery({
            data: {
              currentSession: data.destroySession,
              popmenuConfig,
            },
            query: isAdmin ? adminSessionQuery : currentSessionQuery,
          }));

          // Navigate to home page, to prevent error on authenticated routes
          history.push('/');
        }).catch((err) => {
          showSnackbarError(err);
        });
      }}
    >
      <FormattedMessage id="sessions.sign_out" defaultMessage="Sign Out" />
    </Button>
  );
};

SignOutButton.defaultProps = {
  currentUser: null,
  isAdmin: true,
  onCompleted: null,
};

SignOutButton.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAdmin: PropTypes.bool,
  onCompleted: PropTypes.func,
  popmenuConfig: popmenuConfigShape.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withRouter,
  withIntl,
  withSnackbar,
  withPopmenuConfig,
)(SignOutButton);
