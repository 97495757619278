import React from 'react';
import { compose } from '@shakacode/recompose';

import { toInt } from '../../utils/utils';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import updateUserSubscriptionsMutation from '../../libs/gql/mutations/users/updateUserSubscriptionsMutation.gql';
import userQuery from '../../libs/gql/queries/users/userQuery.gql';

import BasicForm, { CheckBoxGroup, SubmitGroup } from '../../admin/shared/forms/BasicForm';
import Loading from '../Loading';
import Query from '../Query';

const UserSubscriptionsForm = props => (
  <Query
    query={userQuery}
    variables={{ userId: props.currentUser.id }}
  >
    {({ data, loading }) => {
      if (loading || !data || !data.user) {
        return <Loading />;
      }
      const { user } = data;
      return (
        <BasicForm
          defaultValues={{
            isUnsubscribed: user.isUnsubscribed ? 'true' : null,
            unsubscribedRestaurantIds: user.followers.map(follower => (
              follower.isUnsubscribed ? follower.restaurant.id : null
            )),
          }}
          labelPosition="top"
          mutate={{
            mutation: updateUserSubscriptionsMutation,
            toVariables: variables => ({
              ...variables,
              email: props.currentUser.email,
              isUnsubscribed: !!variables.isUnsubscribed,
              unsubscribedRestaurantIds: (variables.unsubscribedRestaurantIds || []).map(id => toInt(id)),
            }),
          }}
        >
          {user.followers.map((follower, i) => (
            <CheckBoxGroup
              key={follower.id}
              field={`unsubscribedRestaurantIds[${i}]`}
              inline
              title={`Unsubscribe from ${follower.restaurant.name}`}
              value={follower.restaurant.id}
            />
          ))}
          <CheckBoxGroup
            field="isUnsubscribed"
            inline
            title="Unsubscribe from all emails"
          />
          <SubmitGroup color="primary" justify="center" title="Update Preferences" />
        </BasicForm>
      );
    }}
  </Query>
);

UserSubscriptionsForm.propTypes = {
  currentUser: currentUserShape.isRequired,
};

export default compose(
  withCurrentUser,
)(UserSubscriptionsForm);
