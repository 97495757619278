import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Button, Icon } from '@popmenu/common-ui';

import { Edit } from '@popmenu/web-icons';
import { withSnackbar } from '../../utils/withSnackbar';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import { withStyles } from '../../utils/withStyles';
import userAvatarFormStyles from '../../assets/jss/shared/userAvatarFormStyles';
import updateUserUploadedAvatarMutation from '../../libs/gql/mutations/users/updateUserUploadedAvatarMutation.gql';

import Mutation from '../Mutation';
import UploadDropzone from '../../admin/shared/forms/uploader/UploadDropzone';
import { withRestaurant } from '../../utils/withRestaurant';

const UserAvatarForm = (props) => {
  const [uploading, setUploading] = useState(false);

  return (
    <Mutation mutation={updateUserUploadedAvatarMutation}>
      {(updateUserAvatar, { loading }) => (
        <div className={props.classes.avatarWrap}>
          <div
            className={props.classes.avatar}
            style={{ backgroundImage: `url("${props.currentUser.thumbnailUrl}")` }}
          >
            <div className={props.classes.avatarLabel}>
              <UploadDropzone
                accept="avatar"
                multiple={false}
                onUploaded={(uploads) => {
                  if (uploads.length > 0) {
                    // Only a single upload allowed, only need to look at the last one
                    const upload = uploads[uploads.length - 1];

                    if (upload) {
                      setUploading(!upload.popModel);

                      if (upload.popModel) {
                        const variables = {
                          uploadedAvatarId: upload.popModel.id,
                        };
                        updateUserAvatar({ variables }).then(() => {
                          props.showSnackbar('Your avatar was updated!');
                        }).catch((err) => {
                          props.showSnackbarError(err, { defaultMessage: 'update_user_avatar.failed' });
                        });
                      }
                    }
                  }
                }}
                restaurant={props.restaurant}
              >
                <Button
                  aria-label="Upload Avatar"
                  className={props.classes.avatarButton}
                  component="a"
                  disabled={loading || uploading}
                  startIcon={<Icon icon={Edit} />}
                  size="small"
                  variant="text"
                />
              </UploadDropzone>
            </div>
          </div>
        </div>
      )}
    </Mutation>
  );
};

UserAvatarForm.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: currentUserShape.isRequired,
  restaurant: PropTypes.object.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withRestaurant,
  withSnackbar,
  withStyles(userAvatarFormStyles),
)(UserAvatarForm);
